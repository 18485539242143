import { createTheme } from '@mui/material';

export const COLORS = {
  PRIMARY: '#46549f',
  SECONDARY: '#4a89ca',
};

const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
  },
});

export default theme;
