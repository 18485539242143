import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as IndonesiaSvg } from '../assets/indonesia.svg';
import bg from '../assets/graphic.jpg';

const LandingImage: React.FC = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    }}
  >
    <Box display="inline-flex" justifyContent="center">
      <img src={bg} alt="indonesia" style={{ width: '70%' }} />
    </Box>
    <Box display="inline-flex" justifyContent="center">
      <IndonesiaSvg style={{ width: '70%', marginLeft: 15 }} />
    </Box>

  </Box>

);

export default LandingImage;
