import React from 'react';
import { Box, Typography } from '@mui/material';
import Logo from '../assets/Logo';

const LogoWithText: React.FC = () => (
  <Box display="flex" justifyContent="space-between" p={2} flexDirection="row" justifySelf="flex-start" width="96vw">
    <Box display="inline-flex">
      <Logo size={56} />
      <Box display="inline-flex" flexDirection="column" px={1}>
        <Typography variant="h6" color="primary">Nerisantara</Typography>
        <Typography variant="body1" color="secondary">Global exports</Typography>
      </Box>
    </Box>
  </Box>
);

export default LogoWithText;
