import { Grid, Box, Button } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import React, { FC } from 'react';
import './assets/App.css';
import LogoWithText from './components/LogoWithText';
import LandingImage from './components/LandingImage';
import theme, { COLORS } from './theme';
import containersImg from './assets/container.jpg';

const App: FC = () => (
  <ThemeProvider theme={theme}>
    <Box>
      <LogoWithText />
      <Grid container spacing={2} style={{ height: 'calc(94vh - 120px)', marginTop: '5vh' }}>
        <Grid item xs={12} md={12} lg={6}>
          <Box display="flex" flexDirection="column">

            <Box
              display="flex"
              sx={{
                fontSize: 'calc(36px + 3vw)',
                color: COLORS.PRIMARY,
              }}
              px={5}
            >
              Bringing Indonesia to the world
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
              <Box display="flex" flexDirection="column">
                <Box display="flex" sx={{ fontSize: 21, color: '#999' }} px={5}>
                  We make importing Indonesian products hassle-free.
                  <br />
                  Check our catalog to learn more about the products we export.
                </Box>

                <Box display="inline-flex" alignItems="center" justifyContent="flex-start" px={5} my={10}>
                  <Button variant="outlined" color="primary" style={{ marginRight: 15, textTransform: 'none' }}>Contact Us</Button>
                  <Button variant="contained" color="secondary">View Catalog</Button>
                </Box>

              </Box>

              <Box display="inline-flex">
                <img src={containersImg} alt="containers" style={{ maxWidth: '100%', maxHeight: 500 }} />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <LandingImage />
        </Grid>
      </Grid>
    </Box>
  </ThemeProvider>
);

export default App;
