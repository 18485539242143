import React from 'react';

const polarToCartesian = (
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number,
) => {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

function describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

  const d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(' ');

  return d;
}

const describeLine = (x1: number, y1: number, x2: number, y2: number) => `M${x1} ${y1} L${x2} ${y2}`;

const COLOR_PRIMARY = '#46549f';
const COLOR_SECONDARY = '#4a89ca';

const ArcDash = ({ arc, line, color }: {arc: string; line: string; color: string}) => (
  <path
    fill="none"
    stroke={color || 'black'}
    strokeWidth={20}
    strokeLinecap="round"
    d={`${arc} ${line}`}
  />
);

const App: React.FC<{size: number}> = ({ size }) => (
  <div className="App">
    <svg viewBox="0 0 228 228" height={size || 21} width={size || 21}>
      <ArcDash
        arc={describeArc(110, 110, 100, 175, 330)}
        line={describeLine(62, 25, 160, 120)}
        color={COLOR_PRIMARY}
      />
      <ArcDash
        arc={describeArc(110, 110, 100, -5, 150)}
        line={describeLine(158, 195, 60, 100)}
        color={COLOR_SECONDARY}
      />
    </svg>
  </div>
);

export default App;
